import Navbar from "./components/general/Navbar";
import Footer from "./components/general/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Maintenance from "./components/pages/Maintenance";
import Contact from "./components/pages/Contact";
import Premises from "./components/pages/Premises";
import Commercial from "./components/pages/Commercial";
import PageNotFound from "./components/pages/PageNotFound";
import Domestic from "./components/pages/Domestic";
import Projects from "./components/pages/Projects";

export default function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Maintenance" element={<Maintenance />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/Premises" element={<Premises />} />
        <Route exact path="/Commercial" element={<Commercial />} />
        <Route exact path="/Domestic" element={<Domestic />} />
        <Route exact path="/Projects" element={<Projects />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}
