import React from "react";
import Content from "../contact/Content";
import Header from "../general/Header";
import contactHeader from "../../assets/contact/contact-header.jpg";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contact Us - SafeTec Fire & Security Ltd.</title>
        <meta
          name="description"
          content="For more information about any of our services, please don’t hesitate to contact us today to speak to a friendly, helpful member of our team."
        />
        <meta
          name="keywords"
          content="SafeTec Fire & Security, North Down, Northern Ireland, professional, cost-effective, reliable, fire solutions, security solutions, domestic customers, commercial customers, industry-leading, expertise"
        />
      </Helmet>
      <Header title="Contact Us" backgroundImage={contactHeader} />
      <Content />
    </div>
  );
}

export default Contact;
