import React, { useState, useEffect } from "react";
import {
  FaBars,
  FaTimes,
  FaChevronDown,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/general/logo.png";

const Navbar = () => {
  useEffect(() => {
    window.addEventListener("hashchange", handleScrollTop);
    return () => window.removeEventListener("hashchange", handleScrollTop);
  }, []);

  const [nav, setNav] = useState(false);

  const [servicesDropdown, setServicesDropdown] = useState(false);

  const [premisesDropdown, setPremisesDropdown] = useState(false);

  const [domesticDropdown, setDomesticDropdown] = useState(false);

  const [commercialDropdown, setCommercialDropdown] = useState(false);

  const handleMobileClick = () => {
    setNav(!nav);
    handleScrollTop();
  };

  const handleClick = () => {
    setServicesDropdown(false);
    handleScrollTop();
  };

  const handleScrollTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100); // Set the timeout to 1000 milliseconds (1 second)
  };

  const handleScrollElement = (id) => {
    handleServicesClick();
    // Scroll to the corresponding section
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleServicesClick = () => {
    setServicesDropdown(!servicesDropdown);
  };

  const handleDropdownClick = () => {
    handleServicesClick();
    handleScrollTop();
  };

  const clearDropdowns = () => {
    setPremisesDropdown(false);
    setCommercialDropdown(false);
    setDomesticDropdown(false);
  };

  const handlePremisesHover = () => {
    clearDropdowns();
    setPremisesDropdown(true);
  };

  const handleCommercialHover = () => {
    clearDropdowns();
    setCommercialDropdown(true);
  };

  const handleDomesticHover = () => {
    clearDropdowns();
    setDomesticDropdown(true);
  };

  return (
    <section id="navbar">
      {/* first navbar */}
      <div className="bg-white w-full h-[100px] md:h-[150px] flex justify-between items-center px-4 md:fixed top-0 left-0 z-30">
        <div className="text-left md:mx-24">
          <NavLink to="/">
            <img src={Logo} alt="logo" className="w-[100px] md:w-[233px]" />
          </NavLink>
        </div>

        {/* contact info */}
        <div className="hidden md:flex md:justify-end md:items-center text-white">
          <div className="mx-4 text-lg">
            <a
              href="mailto:info@safetec-fs.co.uk"
              className="hover:bg-blue hover:scale-105 duration-300 inline-flex items-center justify-center rounded-full px-4 py-2 bg-elm shadow-md"
            >
              <span>info@safetec-fs.co.uk</span>
            </a>
          </div>
          <div className="mx-4 text-lg">
            <a
              href="tel:+4402891311300"
              className="hover:bg-blue hover:scale-105 duration-300 inline-flex items-center justify-center rounded-full px-4 py-2 bg-elm shadow-md"
            >
              <span>028 9131 1300</span>
            </a>
          </div>
          <div className="mx-4 text-lg">
            <a
              href="https://www.facebook.com/safetecfs"
              className="hover:bg-blue hover:scale-105 duration-300 inline-flex items-center justify-center rounded-full px-4 py-3 bg-elm shadow-md"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook />
            </a>
          </div>
          <div className="mx-4 text-lg">
            <a
              href="https://www.instagram.com/safetec_firesecurity/"
              className="hover:bg-blue hover:scale-105 duration-300 inline-flex items-center justify-center rounded-full px-4 py-3 bg-elm shadow-md"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
        </div>

        {/* hamburger menu */}
        <div
          onClick={handleMobileClick}
          className="md:hidden z-50 flex justify-end"
        >
          {!nav ? <FaBars /> : <FaTimes />}
        </div>

        {/* mobile menu */}
        <ul
          className={
            !nav
              ? "hidden"
              : "fixed top-0 left-0 w-full h-screen bg-white text-gunmetal flex flex-col justify-center items-center z-40"
          }
        >
          <li className="py-6 text-xl hover:text-elm duration-300">
            <NavLink to="/" onClick={handleMobileClick}>
              Home
            </NavLink>
          </li>
          <li className="py-6 text-xl hover:text-elm duration-300">
            <NavLink to="/About" onClick={handleMobileClick}>
              About Us
            </NavLink>
          </li>
          <li className="py-6 text-xl hover:text-elm duration-300">
            <NavLink to="/Premises" onClick={handleMobileClick}>
              Life &amp; Premises Safety Solutions
            </NavLink>
          </li>
          <li className="py-6 text-xl hover:text-elm duration-300">
            <NavLink to="/Commercial" onClick={handleMobileClick}>
              Commercial Security Solutions
            </NavLink>
          </li>
          <li className="py-6 text-xl hover:text-elm duration-300">
            <NavLink to="/Domestic" onClick={handleMobileClick}>
              Domestic Security Solutions
            </NavLink>
          </li>
          <li className="py-6 text-xl hover:text-elm duration-300">
            <NavLink to="/Maintenance" onClick={handleMobileClick}>
              Maintenance &amp; Servicing
            </NavLink>
          </li>
          <li className="py-6 text-xl hover:text-elm duration-300">
            <NavLink to="/Projects" onClick={handleMobileClick}>
              Projects
            </NavLink>
          </li>
          <li className="py-6 text-xl hover:text-elm duration-300">
            <NavLink to="/Contact" onClick={handleMobileClick}>
              Contact Us
            </NavLink>
          </li>
        </ul>

        {/* second navbar */}
        <div className="hidden md:flex bg-elm w-full h-[50px] justify-center items-center px-4 absolute top-full left-0 z-40">
          {/* menu */}
          <ul className="hidden md:flex flex-row text-white text-md">
            <li className="mx-4 hover:text-blue duration-300">
              <NavLink to="/" onClick={handleClick}>
                Home
              </NavLink>
            </li>
            <li className="mx-4 hover:text-blue duration-300">
              <NavLink to="/About" onClick={handleClick}>
                About Us
              </NavLink>
            </li>
            <li className="mx-4 relative hover:text-blue duration-300">
              <NavLink
                to="#"
                onClick={handleServicesClick}
                className="flex items-center"
              >
                <span className="mr-2">Our Services</span>
                <FaChevronDown className="h-5 w-5 ml-auto" />
              </NavLink>

              {/* nested submenu 1 */}
              {servicesDropdown && (
                <div className="absolute top-full left-0 bg-white border border-gray-300 rounded-md shadow-lg py-4 px-8">
                  <ul className="space-y-4 w-36 text-gunmetal text-sm">
                    <li
                      className="py-2 hover:text-elm duration-300"
                      onMouseEnter={handlePremisesHover}
                    >
                      <NavLink to="/Premises" onClick={handleDropdownClick}>
                        Life &amp; Premises Safety Solutions
                      </NavLink>

                      {/* nested submenu 2 */}
                      {premisesDropdown && (
                        <div
                          className="absolute top-0 left-full bg-white border border-gray-300 rounded-md shadow-lg py-4 px-8 ml-2"
                          onMouseEnter={handlePremisesHover}
                          style={{ maxHeight: "233px", overflowY: "scroll" }}
                        >
                          <ul className="space-y-4 w-36 text-gunmetal text-sm">
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Premises"
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("life-safety"),
                                    500
                                  )
                                }
                              >
                                Fire Alarm Systems
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Premises"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement("risk-assessments"),
                                    500
                                  )
                                }
                              >
                                Fire Risk Assessments
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Premises"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement("fire-extinguishers"),
                                    500
                                  )
                                }
                              >
                                Fire Extinguishers
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Premises"
                                ises
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("hearing-loop"),
                                    500
                                  )
                                }
                              >
                                Hearing Loop Systems
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Premises"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement("disabled-refuge"),
                                    500
                                  )
                                }
                              >
                                Disabled Refuge Systems
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Premises"
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("nurse-call"),
                                    500
                                  )
                                }
                              >
                                Nurse Call and Warden Call Systems
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Premises"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement("door-automation"),
                                    500
                                  )
                                }
                              >
                                Door Automation
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Premises"
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("public-address"),
                                    500
                                  )
                                }
                              >
                                Public Address Systems
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                    <li
                      className="py-2 hover:text-elm duration-300"
                      onMouseEnter={handleCommercialHover}
                    >
                      <NavLink to="/Commercial" onClick={handleDropdownClick}>
                        Commercial Security Solutions
                      </NavLink>
                      {/* nested submenu 2 */}
                      {commercialDropdown && (
                        <div
                          className="absolute top-0 left-full bg-white border border-gray-300 rounded-md shadow-lg py-4 px-8 ml-2"
                          onMouseEnter={handleCommercialHover}
                          style={{ maxHeight: "233px", overflowY: "scroll" }}
                        >
                          <ul className="space-y-4 w-36 text-gunmetal text-sm">
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Commercial"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement(
                                        "intruder-alarms-commercial"
                                      ),
                                    500
                                  )
                                }
                              >
                                Intruder Alarms
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Commercial"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement("cctv-commercial"),
                                    500
                                  )
                                }
                              >
                                CCTV
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Commercial"
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("access-control"),
                                    500
                                  )
                                }
                              >
                                Access Control
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Commercial"
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("intercoms"),
                                    500
                                  )
                                }
                              >
                                Intercoms
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Commercial"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement(
                                        "wireless-networking"
                                      ),
                                    500
                                  )
                                }
                              >
                                Wireless and Networking
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                    <li
                      className="py-2 hover:text-elm duration-300"
                      onMouseEnter={handleDomesticHover}
                    >
                      <NavLink to="/Domestic" onClick={handleDropdownClick}>
                        Domestic Security Solutions
                      </NavLink>
                      {/* nested submenu 2 */}
                      {domesticDropdown && (
                        <div
                          className="absolute top-0 left-full bg-white border border-gray-300 rounded-md shadow-lg py-4 px-8 ml-2"
                          onMouseEnter={handleDomesticHover}
                          style={{ maxHeight: "233px", overflowY: "scroll" }}
                        >
                          <ul className="space-y-4 w-36 text-gunmetal text-sm">
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Domestic"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement(
                                        "intruder-alarms-domestic"
                                      ),
                                    500
                                  )
                                }
                              >
                                Intruder Alarms
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Domestic"
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("cctv-domestic"),
                                    500
                                  )
                                }
                              >
                                CCTV
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Domestic"
                                onClick={() =>
                                  setTimeout(
                                    () =>
                                      handleScrollElement("security-lighting"),
                                    500
                                  )
                                }
                              >
                                Security Lighting and Perimeter Detection
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Domestic"
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("wifi"),
                                    500
                                  )
                                }
                              >
                                WiFi and Access Points
                              </NavLink>
                            </li>
                            <li className="py-2 hover:text-elm duration-300">
                              <NavLink
                                to="/Domestic"
                                onClick={() =>
                                  setTimeout(
                                    () => handleScrollElement("intercoms"),
                                    500
                                  )
                                }
                              >
                                Intercoms and Doorbells
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </li>

            <li className="mx-4 hover:text-blue duration-300">
              <NavLink to="/Maintenance" onClick={handleClick}>
                Maintenance &amp; Servicing
              </NavLink>
            </li>
            <li className="mx-4 hover:text-blue duration-300">
              <NavLink to="/Projects" onClick={handleClick}>
                Projects
              </NavLink>
            </li>
            <li className="mx-4 hover:text-blue duration-300">
              <NavLink to="/Contact" onClick={handleClick}>
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
