import React from "react";
import { Helmet } from "react-helmet";
import Header from "../general/Header";
import Content from "../general/Content";
import domesticHeader from "../../assets/domestic/domestic-header.jpg";
import intercomImage from "../../assets/domestic/intercom.jpg";
import alarmImage from "../../assets/domestic/intruder-alarm.jpg";
import securityLighting from "../../assets/domestic/security-lighting.jpg";
import wifiImage from "../../assets/domestic/wifi.jpg";
import cctvImage from "../../assets/domestic/cctv.jpg";
import slide3 from "../../assets/domestic/domestic-slides/slide3.jpg";
import slide4 from "../../assets/domestic/domestic-slides/slide4.jpg";
import slide5 from "../../assets/domestic/domestic-slides/slide5.jpg";
import Slideshow from "../general/Slideshow";

const slideImages = [slide3, slide4, slide5];

function Domestic() {
  return (
    <div>
      <Helmet>
        <title>
          Domestic Security Solutions - SafeTec Fire & Security Ltd.
        </title>
        <meta
          name="description"
          content="Wired and wireless intruder alarms, smart CCTV and analytics, security lighting and perimeter detection, and WiFi and access points."
        />
        <meta
          name="keywords"
          content="SafeTec Fire & Security, North Down, Northern Ireland, professional, cost-effective, reliable, fire solutions, security solutions, domestic customers, commercial customers, industry-leading, expertise"
        />
      </Helmet>
      <Header title={"Domestic Security Solutions"} backgroundImage={domesticHeader} />
      <Content
        id={"intruder-alarms-domestic"}
        heading={"Intruder Alarms"}
        headingColor={"text-blue"}
        imageSrc={alarmImage}
        imageAlt={"Intruder alarm on a wall"}
        text={
          <div>
            <p className="text-lg">
              Professionally installed Intruder Alarms in homes and businesses
              are proven to be one of the best methods to deter unwanted
              intruders from damaging business and destroying confidence in
              safety which is very difficult to regain
              <br />
              Statistics show that burglaries are more and more frequent. Here
              at SafeTec Fire & Security, we have the knowledge to
              professionally design and install and comply with standards
              PD6662:2017
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">
              Wired Intruder Alarms
            </h1>
            <p className="text-lg mt-2">
              Wired intruder alarm systems are a reliable and cost-effective way
              to protect your home or business from burglary and theft. These
              systems consist of sensors that are hard-wired to a central
              control panel, which is typically located in a secure area such as
              a basement or closet.
              <br />
              When an intruder enters the protected area, the sensors detect
              their presence and trigger the alarm, which sounds a loud siren or
              sends a notification to a monitoring center. The control panel can
              also be programmed to alert the authorities directly if necessary.
              <br />
              One of the main advantages of a wired intruder alarm system is its
              reliability. Wired systems are always connected and ready to
              respond to any threat. Another advantage of wired systems is that
              they can be customized to meet the specific needs of your
              property. Sensors can be placed in strategic locations to provide
              maximum coverage, and the system can be integrated with other
              security features such as CCTV cameras and access control systems.
              <br />
              Overall, a wired intruder alarm system is a smart investment for
              anyone who wants to protect their property and keep their loved
              ones safe. With professional installation and regular maintenance,
              these systems provide reliable and effective security for years to
              come.
            </p>

            <h1 className="text-xl font-bold text-blue mt-6">
              Wireless Systems
            </h1>
            <p className="text-lg mt-2">
              If properties haven’t been wired for an intruder alarm, don’t
              worry, Wireless systems are a great, cost effective and reliable
              method of protection without the disruption of lifting floors and
              tracking walls.
              <br />
              Perfect for finished homes, listed buildings or even separated
              builds. Designed to the correct Grading we have you covered. See
              our range of systems in the Products tab.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">Monitoring</h1>
            <p className="text-lg mt-2">
              All our designed systems can be operated via Mobile App anytime
              and anywhere in the world whilst giving you full operability and
              notification as if you were in front of your system keypad.
              <br />
              As part of a designed intruder alarm system, we can also provide
              Remote Monitoring of your system and protect the premises 24/7.
              <br />
              Insurance providers will often specify that the premises will need
              Graded Remote Monitoring. We can install communication devices
              that work over Broadband and GSM with battery backup to ensure
              that there is continued connection to our UK based Alarm Receiving
              Centre (ARC). Keyholders will be contacted upon alert.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">
              Why Get an Intruder Alarm?
            </h1>
            <ul className="list-disc list-inside text-lg mt-2">
              <li>Premises protection 24/7</li>
              <li>Full control and alert notification via app.</li>
              <li>Remote monitoring to ARC and keyholder response</li>
              <li>Lower insurance premiums</li>
              <li>Remote support</li>
              <li>Wired and wireless with no disruption</li>
              <li>
                Third party equipment connection such as fire alarms, freezer
                alarms and many others
              </li>
            </ul>
          </div>
        }
      />
      <Content
        id={"cctv-domestic"}
        heading={"CCTV"}
        headingColor={"text-white"}
        imagePosition={"left"}
        bgColor={"bg-elm"}
        textColor={"text-white"}
        imageSrc={cctvImage}
        imageAlt={"CCTV camera on a wall"}
        text={
          <div>
            <p className="text-lg">
              CCTV has become an important factor for premises security and can
              be a great deterrent for would be thieves and people with intent
              to damage.
              <br />
              SafeTec Fire & Security has the specialist knowledge to design and
              install industry leading, cost effective systems in line with
              industry standards BS PN 50132 – 7 1996
              <br />
              We can provide a variant of CCTV solutions to suit customer needs
              and structure.
            </p>
            <ul className="list-disc list-inside text-lg mt-6">
              <li>Pan Tilt Cameras (PTZs)</li>
              <li>Vandal-proof Cameras</li>
              <li>Panoramic Cameras</li>
              <li>Floodlight Cameras</li>
              <li>Audio Cameras</li>
              <li>4G Cameras</li>
              <li>
                Analytics such as motion detection, line crossing, ANPR and many
                more
              </li>
              <li>Remote access via app, anywhere, anytime</li>
              <li>
                Systems can be local recording or network recording with storage
                arrays to store multiple hard drives to get the desired
                recording length
              </li>
            </ul>
            <p className="text-lg mt-6">
              Camera will be the highest quality resolutions, excellent view day
              and night and can easily replace older systems utilizing existing
              cabling infrastructure.
              <br />
              CCTV analytics can be a very useful tool and can be programmed to
              monitor activity in its view to provide alert / desired warning if
              required. Analytics such as: Thermal Temperature Screening;
              Intrusion Detection; Line Crossing; ANPR; Many more...
            </p>
          </div>
        }
      />
      <Content
        id={"security-lighting"}
        heading={"Security Lighting and Perimeter Detection"}
        headingColor={"text-blue"}
        imageSrc={securityLighting}
        imageAlt={"Security lighting on a tree"}
        imagePosition={"right"}
        bgColor={"bg-white"}
        text={
          <div>
            <p className="text-lg">
              Poorly illuminated areas are a haven for criminals and car
              thieves. SafeTec Fire & Security have an excellent range of
              bespoke floodlight and external detection systems solutions to
              illuminate your property, providing safe passage for your family
              or staff whilst providing the capability to alert residing tenants
              and deter thieves.
            </p>
          </div>
        }
      />
      <Content
        id={"wifi"}
        heading={"WiFi and Access Points"}
        headingColor={"text-white"}
        imagePosition={"left"}
        imageSrc={wifiImage}
        imageAlt={"Wifi access point on a wall"}
        bgColor={"bg-elm"}
        textColor={"text-white"}
        text={
          <div>
            <p className="text-lg">
              Networks and WiFi is an essential aspect of any home (or teenagers
              social life) and needs to be strong and reliable throughout. We
              can provide the essential equipment to give you structure to
              empower your home to provide a fast network and wireless range to
              cover every area required.
              <br />
              We can install Wifi Access Points to increase range and Point to
              Point beams if network access is needed in a remote location ie.
              Garage / Calving Shed, etc.
            </p>
          </div>
        }
      />
            <Content
        id={"intercoms"}
        heading={"Intercoms and Doorbells"}
        headingColor={"text-blue"}
        imageSrc={intercomImage}
        imageAlt={"Intercom system and doorbell camera"}
        imagePosition={"right"}
        text={
          <div>
            <p className="text-lg">
              Intercoms are essential for many homeowners and business owners.
              SafeTec F&S can provide a multitude of different systems to enable
              the client to communicate with visitors and release locking
              devices / automatic gates.
            </p>
            <ul className="list-disc list-inside text-lg mt-6">
              <li>Audio/video as standard</li>
              <li>Stylish and contemporary designs</li>
              <li>LCD Handsets</li>
              <li>
                1 to 1 kits or Scalable Multi-Handset – Multi-Door Station
                designs
              </li>
              <li>GSM options for remote locations</li>
            </ul>
          </div>
        }
      />
      <Slideshow images={slideImages} />
    </div>
  );
}

export default Domestic;
