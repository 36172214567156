import React from "react";
import contactImage from "../../assets/contact/contact-image.jpg";
import { FaPhone, FaFacebook, FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

function Content() {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 sm:px-6 lg:px-8">
        <h1 className="my-8 text-3xl font-bold sm:text-4xl text-blue">
          How to contact us
        </h1>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 items-center">
          <div className="relative h-auto overflow-hidden sm:h-80 lg:h-full flex">
            <div className="md:my-6">
              <p className="text-lg text-gunmetal leading-relaxed">
                For more information about any of our services, please don’t
                hesitate to contact us today to speak to a friendly, helpful
                member of our team.
              </p>
              <h1 className="text-2xl font-bold text-blue mt-6">
                Our Contact Details
              </h1>
              <div className="flex flex-row items-center mt-2">
                <FaPhone className="mr-4" />
                <a
                  href="tel:+4402891311300"
                  className=" hover:text-elm duration-300"
                >
                  028 9131 1300
                </a>
              </div>
              <div className="flex flex-row items-center mt-2">
                <HiOutlineMail className="mr-4" />
                <a
                  href="mailto:info@safetec-fs.co.uk"
                  className="hover:text-elm duration-300"
                >
                  info@safetec-fs.co.uk
                </a>
              </div>
              <h1 className="text-2xl font-bold text-blue mt-6">Socials</h1>
              <div className="flex flex-row items-center mt-2 gap-1">
                <a
                  href="https://www.facebook.com/safetecfs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook className="mr-2 text-xl hover:text-elm duration-300" />
                </a>
                <a
                  href="https://www.instagram.com/safetec_firesecurity/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="mr-2 text-xl hover:text-elm duration-300" />
                </a>
              </div>
            </div>
          </div>
          <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={contactImage}
              className="absolute inset-0 w-full h-full object-center object-cover"
              alt="Alarm keypad displaying 'Safetec F+S'"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
