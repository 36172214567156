import React from "react";
import ReviewItem from "./ReviewItem";

const Reviews = () => {
  return (
    <section className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-start">
          <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue sm:text-4xl">
            What our customers are saying
          </h1>
          <p className="mt-4 max-w-2xl text-lg text-gunmetal">
            Check out some of the amazing feedback we've received from our
            customers. You can also read more reviews on our <a className="font-bold hover:text-elm duration-300"href="https://www.bark.com/en/gb/company/safetec-fire--security/RQ6Av/" target="_blank" rel="noreferrer">
            Bark profile</a> or on <a className="font-bold hover:text-elm duration-300"href="https://goo.gl/maps/LJeSWDaoy62Ae1Az5" target="_blank" rel="noreferrer">Google.</a>
          </p>
        </div>

        <div className="mt-10">
          <div className="mt-10 -mx-4 relative">
            <div className="mx-auto max-w-screen-lg xl:max-w-screen-xl">
              <div className="flex flex-wrap">
                <ReviewItem
                  name="Irwin Price"
                  reviewLink={"https://g.co/kgs/9Ksbgq"}
                  quote="On time, knowledgeable and professional."
                />
                <ReviewItem
                  name="John Neely"
                  reviewLink={"https://g.co/kgs/pB1DEg"}
                  quote="I dealt directly with John, who was professional and informative from the initial contact to job completion. Had an alarm and CCTV installed and would highly recommend. Professional install and service. Thank you."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
