import React from "react";
import footerImage from "../../assets/general/footer.jpg";
import { FaFacebook, FaInstagram } from "react-icons/fa";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer aria-label="Site Footer" className="bg-white lg:grid lg:grid-cols-5">
      <div className="relative block h-32 lg:col-span-2 lg:h-full">
        <img
          src={footerImage}
          alt="Alarm keypad"
          className="absolute inset-0 object-cover object-top md:object-center w-full h-full"
        />
      </div>

      <div className="px-4 py-16 sm:px-6 lg:col-span-3 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div>
            <p>
              <span className="text-xs tracking-wide text-gunmetal uppercase">
                Call us
              </span>

              <a
                href="tel:+4402891311300"
                className="block text-2xl font-medium hover:text-elm duration-300 sm:text-3xl"
              >
                028 9131 1300
              </a>
            </p>

            <ul className="text-gunmetal mt-8 space-y-1 text-sm">
              <li>Monday to Friday: 8:30 - 17:00</li>
              <li>Service callout 24/7</li>
            </ul>

            <ul className="flex gap-6 mt-8">
              <li>
                <a
                  href="https://www.facebook.com/safetecfs"
                  rel="noreferrer"
                  target="_blank"
                  className=" transition hover:text-elm duration-300"
                >
                  <span className="sr-only">Facebook</span>
                  <FaFacebook className="w-6 h-6" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/safetec_firesecurity/"
                  rel="noreferrer"
                  target="_blank"
                  className=" transition hover:text-elm duration-300"
                >
                  <span className="sr-only">Instagram</span>
                  <FaInstagram className="w-6 h-6" />
                </a>
              </li>
            </ul>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <p className="font-medium text-gunmetal">Services</p>

              <nav aria-label="Footer Navigation - Services" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a href="/Premises" className=" transition hover:text-elm">
                      Life & Premises Safety Solutions
                    </a>
                  </li>

                  <li>
                    <a href="/Commercial" className=" transition hover:text-elm">
                      Commercial Security Solutions
                    </a>
                  </li>

                  <li>
                    <a href="/Domestic" className=" transition hover:text-elm">
                      Domestic Security Solutions
                    </a>
                  </li>

                  <li>
                    <a href="/Maintenance" className=" transition hover:text-elm">
                      Maintenance & Servicing
                    </a>
                  </li>

                  <li>
                    <a href="/Projects" className=" transition hover:text-elm">
                      Projects
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div>
              <p className="font-medium text-gunmetal">Company</p>

              <nav aria-label="Footer Navigation - Company" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a href="/" className=" transition hover:text-elm">
                      Home
                    </a>
                  </li>

                  <li>
                    <a href="/About" className=" transition hover:text-elm">
                      About Us
                    </a>
                  </li>

                  <li>
                    <a href="/Contact" className=" transition hover:text-elm">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="pt-12 mt-12 border-t border-gray-100">
          <div className="sm:flex sm:items-center sm:justify-between">
            <p className="mt-8 text-xs text-gunmetal sm:mt-0">
              &copy; {currentYear}. SafeTec Fire & Security. All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
