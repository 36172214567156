import React from "react";

function Header({ title, backgroundImage }) {
  return (
    <section
      className="md:mt-[200px] relative overflow-hidden bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="bg-black/25 p-8 md:p-12 lg:px-16 lg:py-24">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-white sm:text-3xl md:text-5xl">
            {title}
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Header;
