import React, { useState, useEffect } from "react";

const Slideshow = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <div className="h-[500px] w-full overflow-hidden relative">
      {images.map((image, index) => (
        <img
          key={index}
          className={`h-[500px] object-cover object-center w-full absolute top-0 left-0 transition-opacity duration-1000 ${
            index === currentImageIndex ? "opacity-100" : "opacity-0"
          }`}
          src={image}
          alt={`Slide ${index}`}
        />
      ))}
    </div>
  );
};

export default Slideshow;
