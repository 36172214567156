import React from "react";

function PageNotFound() {
  return (
    <div className="grid h-screen px-4 bg-primary place-content-center">
      <h1 className="text-2xl tracking-widest text-elm uppercase">
        404 | Not Found
      </h1>
    </div>
  );
}

export default PageNotFound;
