import React from "react";
import Hero from "../home/Hero";
import Reviews from "../home/Reviews";
import Content from "../home/Content";
import image1 from "../../assets/home/life-and-premises.jpg";
import image2 from "../../assets/home/commercial-security.jpg";
import image3 from "../../assets/home/domestic-security.jpg";
import { Helmet } from "react-helmet";
import Slideshow from "../general/Slideshow";
import slide1 from "../../assets/home/home-slides/slide1.jpg";
import slide2 from "../../assets/home/home-slides/slide2.jpg";
import slide3 from "../../assets/home/home-slides/slide3.jpg";
import slide4 from "../../assets/home/home-slides/slide4.jpg";
import slide5 from "../../assets/home/home-slides/slide5.jpg";
import slide6 from "../../assets/home/home-slides/slide6.jpg";
import slide7 from "../../assets/home/home-slides/slide7.jpg";
import slide8 from "../../assets/home/home-slides/slide8.jpg";
import slide9 from "../../assets/home/home-slides/slide9.jpg";
import slide10 from "../../assets/home/home-slides/slide10.jpg";

function Home() {
  const slideImages = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
  ];

  return (
    <div>
      <Helmet>
        <title>
          Cost Effective and Professional, Safe Keeping Technology - SafeTec
          Fire & Security Ltd.
        </title>
        <meta
          name="description"
          content="SafeTec Fire & Security is based in North Down and serves all of
          Northern Ireland. We provide professional, cost effective and
          reliable fire and security solutions to both domestic and commercial
          customers. We have the experience and expertise to provide you with
          industry leading solutions."
        />
        <meta
          name="keywords"
          content="SafeTec Fire & Security, North Down, Northern Ireland, professional, cost-effective, reliable, fire solutions, security solutions, domestic customers, commercial customers, industry-leading, expertise"
        />
      </Helmet>
      <Hero />
      <Content
        image={image1}
        imageAlt={"Life & Premises Safety Solutions"}
        imagePosition="right"
        bgColor="bg-white"
        textColor="text-black"
        headingColor="text-blue"
        heading="Life & Premises Safety Solutions"
        text={
            <ul className="mt-2 list-disc list-inside">
              <li>Fire Alarm Systems</li>
              <li>Fire Extinguishers</li>
              <li>Fire Risk Assessments</li>
              <li>Nurse Call and Warden Call Systems</li>
              <li>Disabled Refuge Systems</li>
              <li>Hearing Loop Systems</li>
              <li>Door Automation</li>
            </ul>
        }
      />
      <Content
        image={image2}
        imageAlt={"Commercial Security Solutions"}
        imagePosition="left"
        bgColor="bg-elm"
        textColor="text-white"
        headingColor="text-white"
        heading="Commercial Security Solutions"
        text={
            <ul className="mt-2 list-disc list-inside">
              <li>Graded Intruder Alarms and 24/7 Monitoring</li>
              <li>CCTV Solutions and Analytics</li>
              <li>Access Control and Intercoms</li>
              <li>Specialist Lock Fitting and Locksmithing</li>
              <li>Anti-theft Solutions</li>
              <li>Wireless and Networking</li>
            </ul>
        }
      />
      <Content
        image={image3}
        imageAlt={"Domestic Security Solutions"}
        imagePosition="right"
        bgColor="bg-white"
        textColor="text-black"
        headingColor="text-blue"
        heading="Domestic Security Solutions"
        text={
            <ul className="mt-2 list-disc list-inside">
              <li>Wired & Wireless Intruder Alarms</li>
              <li>Smart CCTV & Analytics</li>
              <li>Security Lighting and Perimeter Detection</li>
              <li>Wifi and Access Point</li>
              <li>Intercoms and Doorbells</li>
            </ul>
        }
      />
      <Slideshow images={slideImages} />
      <Reviews />
    </div>
  );
}

export default Home;
