import React from "react";
import Header from "../general/Header";
import aboutHeader from "../../assets/about/about-header.jpg";
import Content from "../about/Content";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div>
      <Helmet>
        <title>About Us - SafeTec Fire & Security Ltd.</title>
        <meta
          name="description"
          content="SafeTec Fire & Security is a professionally trained and highly accredited, family run business, with over 20 years of experience in the industry. "
        />
        <meta
          name="keywords"
          content="SafeTec Fire & Security, North Down, Northern Ireland, professional, cost-effective, reliable, fire solutions, security solutions, domestic customers, commercial customers, industry-leading, expertise"
        />
      </Helmet>
      <Header title="About Us" backgroundImage={aboutHeader} />
      <Content />
    </div>
  );
}

export default About;
