function Content({
  image,
  imagePosition = "left",
  imageAlt,
  bgColor = "bg-white",
  textColor = "text-black",
  headingColor,
  heading,
  text,
}) {
  const imageStyle = imagePosition === "left" ? "order-1" : "order-2";
  const textStyle = imagePosition === "left" ? "order-2" : "order-1";

  return (
    <section className={`${bgColor} ${textColor} text-left py-16 md:py-32`}>
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div
          className="md:flex md:flex-row md:items-center md:justify-center"
          style={{ minHeight: "18rem" }}
        >
          <div
            className={`p-4 ${textStyle} md:w-1/2 md:h-auto md:py-0 md:pl-10`}
          >
            <div className="mx-auto">
              <h1
                className={`text-blue text-3xl font-bold sm:text-4xl md:text-left ${headingColor}`}
              >
                {heading}
              </h1>
              <div className="mt-8">
                <article className="space-y-4 text-xl">
                  <div>{text}</div>
                </article>
              </div>
            </div>
          </div>
          <div
            className={`p-4 flex justify-center ${imageStyle} md:w-1/2 md:h-auto`}
          >
            <div className="relative rounded-full overflow-hidden w-72 h-72 md:w-96 md:h-96 shadow-xl flex justify-center items-center">
              <div className="absolute inset-0 bg-blue opacity-50 rounded-full z-10"></div>
              <img
                className="w-4/5 h-4/5 object-cover rounded-full z-20"
                src={image}
                alt={imageAlt}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
