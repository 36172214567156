import React from "react";

function Content() {
  return (
    <section className="about-bg relative">
      <div className="absolute inset-0 sm:bg-transparent bg-white opacity-80 md:opacity-100 md:bg-gradient-to-r md:from-white/100 md:to-white/50 z-0"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 py-8 sm:py-16 sm:px-6 lg:px-8 z-10">
        <h1 className="my-8 text-3xl font-bold sm:text-4xl text-blue">
          About Our Company
        </h1>
        <p className="text-black md:py-6 max-w-lg text-lg sm:leading-relaxed">
          Safetec Fire & Security is based In North Down and provides a service
          to the whole of NI. We are a family run business and building solid,
          happy relationships with our clients is our passion. We possess over
          20 years of experience in the industry and we are professionally trained and
          highly accredited.
          <br />
          <br />
          We carry out installation and servicing of all fire and security
          disciplines to provide our clients the ease of a ‘one company for all’
          service. We ensure systems are kept in perfect condition and reliable
          for years to come.
          <br />
          <br />
          We serve Domestic, Commercial and Industrial sectors and we keep up to
          date with the latest in technology advances to provide whatever
          solution to suit your needs no matter how bespoke.
          <br />
          <br />
          To support our clients, we provide 24/7 call out and 24/7 remote
          monitoring of your security systems.
          <br />
          <br />
          Have the confidence that we will provide <span className="font-bold">"Cost Effective and Professional, Safe Keeping Technology".</span>
        </p>
      </div>
    </section>
  );
}

export default Content;
