import React from "react";
import Header from "../general/Header";
import headerImage from "../../assets/maintenance/maintenance-header.jpg";
import fireAlarmImage from "../../assets/maintenance/maintenance-image.jpg";
import intruderAlarmImage from "../../assets/maintenance/intruder-alarm.jpg";
import Content from "../general/Content";
import { Helmet } from "react-helmet";

function Maintenance() {
  return (
    <div>
      <Helmet>
        <title>Maintenance and Servicing - SafeTec Fire & Security Ltd.</title>
        <meta
          name="description"
          content="Keep in line with the Legal Requirement and current standards BS
          5839-1:2017. Fire Alarms must be professionally maintained. Need an intruder alarm serviced or upgraded? No problem. Give us a
          call and we can help you secure your home."
        />
        <meta
          name="keywords"
          content="SafeTec Fire & Security, North Down, Northern Ireland, professional, cost-effective, reliable, fire solutions, security solutions, domestic customers, commercial customers, industry-leading, expertise"
        />
      </Helmet>
      <Header title={"Maintenance & Servicing"} backgroundImage={headerImage} />
      <Content
        heading="Have an existing fire alarm system?"
        text={
          <p>
            Keep in line with the Legal Requirement and current standards BS
            5839-1:2017. Fire Alarms must be professionally maintained.
            <br />
            We can provide contracted Maintenance and Certification. Visits can
            be an annual visit, 6 monthly or quarterly. We maintain the systems
            to a high standard and ensure they stay healthy and compliant for
            years to come. <br />
            During these services we will test all smoke / heat detectors and
            alert devices to ensure the whole of the premises is completely
            protected.
          </p>
        }
        bgColor="bg-white"
        headingColor="text-blue"
        textColor="text-black"
        imageSrc={fireAlarmImage}
        imageAlt={"Fire alarm being maintained by a technician"}
        imagePosition="right"
      />
      <Content
        heading="Have an existing intruder alarm system?"
        text={
          <p>
            Need an intruder alarm serviced or upgraded? No problem. Give us a
            call and we can help you secure your home.
            <br />
            Intruder alarms rely on battery back ups to provide adequate voltage
            when mains failure has occurred. These batteries need routinely
            tested or they can lead to further issues when neglected. <br />
            It is also important that detection devices and access control locks
            are all tested and maintained to ensure they provide the essential
            service when needed most.
          </p>
        }
        bgColor="bg-elm"
        headingColor="text-white"
        textColor="text-white"
        imageSrc={intruderAlarmImage}
        imageAlt={
          "Technician performing maintenance on an intruder alarm system"
        }
        imagePosition="left"
      />
      <div className="flex flex-col items-center justify-center bg-white py-8">
        <p className="text-lg text-center text-black">
          Contact us now for a no obligation survey of your premises.
        </p>
        <p className="text-xl font-bold text-center text-black mt-2">
          Please contact{" "}
          <a href="tel:+4402891311300" className="hover:text-elm duration-300">
            028 9131 1300
          </a>
        </p>
      </div>
    </div>
  );
}

export default Maintenance;
