import React from "react";
import Header from "../general/Header";
import Content from "../general/Content";
import networksImage from "../../assets/commercial/data-rack.jpg";
import commercialHeader from "../../assets/commercial/commercial-header.jpg";
import intercomImage from "../../assets/commercial/intercom.jpg";
import doorAutomationImage from "../../assets/commercial/door-automation.jpg";
import cctvImage from "../../assets/commercial/cctv-outside.jpg";
import alarmImage from "../../assets/commercial/alarm-keypad.jpg";
import { Helmet } from "react-helmet";
import slide1 from "../../assets/commercial/commercial-slides/slide1.jpg";
import slide2 from "../../assets/commercial/commercial-slides/slide2.jpg";
import slide3 from "../../assets/commercial/commercial-slides/slide3.jpg";
import slide4 from "../../assets/commercial/commercial-slides/slide4.jpg";
import slide6 from "../../assets/commercial/commercial-slides/slide6.jpg";
import slide7 from "../../assets/commercial/commercial-slides/slide7.jpg";
import slide8 from "../../assets/commercial/commercial-slides/slide8.jpg";
import slide9 from "../../assets/commercial/commercial-slides/slide9.jpg";
import slide10 from "../../assets/commercial/commercial-slides/slide10.jpg";
import slide11 from "../../assets/commercial/commercial-slides/slide11.jpg";
import slide12 from "../../assets/commercial/commercial-slides/slide12.jpg";
import slide13 from "../../assets/commercial/commercial-slides/slide13.jpg";
import Slideshow from "../general/Slideshow";

const slideImages = [slide1, slide2, slide3, slide4, slide6, slide7, slide8, slide9, slide10, slide11, slide12, slide13];

function Commercial() {
  return (
    <div>
      <Helmet>
        <title>
          Commercial Security Solutions - SafeTec Fire & Security Ltd.
        </title>
        <meta
          name="description"
          content="Graded intruder alarms and 24/7 monitoring, CCTV solutions and analytics, access Control and intercoms,
          specialist lock fitting, anti-theft solutions, and wireless and data networking."
        />
        <meta
          name="keywords"
          content="SafeTec Fire & Security, North Down, Northern Ireland, professional, cost-effective, reliable, fire solutions, security solutions, domestic customers, commercial customers, industry-leading, expertise"
        />
      </Helmet>
      <Header title={"Commercial Security Solutions"} backgroundImage={commercialHeader} />
      <Content
        id={"intruder-alarms-commercial"}
        heading={"Intruder Alarms"}
        headingColor={"text-blue"}
        imageSrc={alarmImage}
        imageAlt={"Intruder alarm keypad on a wall"} 
        text={
          <div>
            <p className="text-lg">
              Professionally installed Intruder Alarms in homes and businesses
              are proven to be one of the best methods to deter unwanted
              intruders from damaging business and destroying confidence in
              safety which is very difficult to regain
              <br />
              Statistics show that burglaries are more and more frequent. Here
              at SafeTec Fire & Security, we have the knowledge to
              professionally design and install and comply with standards
              PD6662:2017
              <br />
              We use reliable and responsive equipment. Systems can be wired or
              wireless and can be installed in variant ways to suit customer
              requirements
            </p>
            <ul className="list-disc list-inside text-lg mt-6">
              <li>Wired system with external sounders with LED Display</li>
              <li>
                Wired system with external illuminating sounder and app
                notification control
              </li>
              <li>
                Wireless ‘audible Only’ with LED Display and wireless Sounder
              </li>
              <li>
                Wireless system with external sounder, LCD keypad and APP
                notification control
              </li>
            </ul>
            <h1 className="text-xl font-bold text-blue mt-6">
              Wireless Systems
            </h1>
            <p className="text-lg mt-2">
              If properties haven’t been wired for an intruder alarm, don’t
              worry, Wireless systems are a great, cost effective and reliable
              method of protection without the disruption of lifting floors and
              tracking walls.
              <br />
              Perfect for finished homes, listed buildings or even separated
              builds. Designed to the correct Grading we have you covered. See
              our range of systems in the Products tab.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">Monitoring</h1>
            <p className="text-lg mt-2">
              All our designed systems can be operated via Mobile App anytime
              and anywhere in the world whilst giving you full operability and
              notification as if you were in front of your system keypad.
              <br />
              As part of a designed intruder alarm system, we can also provide
              Remote Monitoring of your system and protect the premises 24/7.
              <br />
              Insurance providers will often specify that the premises will need
              Graded Remote Monitoring. We can install communication devices
              that work over Broadband and GSM with battery backup to ensure
              that there is continued connection to our UK based Alarm Receiving
              Centre (ARC). Keyholders will be contacted upon alert.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">
              Why Get an Intruder Alarm?
            </h1>
            <ul className="list-disc list-inside text-lg mt-2">
              <li>Premises protection 24/7</li>
              <li>Full control and alert notification via app.</li>
              <li>Remote monitoring to ARC and keyholder response</li>
              <li>Lower insurance premiums</li>
              <li>Remote support</li>
              <li>Wired and wireless with no disruption</li>
              <li>
                Third party equipment connection such as fire alarms, freezer
                alarms and many others
              </li>
            </ul>
          </div>
        }
      />
      <Content
        id={"cctv-commercial"}
        heading={"CCTV"}
        headingColor={"text-white"}
        imageSrc={cctvImage}
        imageAlt={"CCTV camera system"}
        imagePosition={"left"}
        bgColor={"bg-elm"}
        textColor={"text-white"}
        text={
          <div>
            <p className="text-lg">
              CCTV has become an important factor for premises security and can
              be a great deterrent for would be thieves and people with intent
              to damage.
              <br />
              SafeTec Fire & Security has the specialist knowledge to design and
              install industry leading, cost effective systems in line with
              industry standards BS PN 50132 – 7 1996
              <br />
              We can provide a variant of CCTV solutions to suit customer needs
              and structure.
            </p>
            <ul className="list-disc list-inside text-lg mt-6">
              <li>Pan Tilt Cameras (PTZs)</li>
              <li>Vandal-proof Cameras</li>
              <li>Panoramic Cameras</li>
              <li>Floodlight Cameras</li>
              <li>Audio Cameras</li>
              <li>4G Cameras</li>
              <li>
                Analytics such as motion detection, line crossing, ANPR and many
                more
              </li>
              <li>Remote access via app, anywhere, anytime</li>
              <li>
                Systems can be local recording or network recording with storage
                arrays to store multiple hard drives to get the desired
                recording length
              </li>
            </ul>
            <p className="text-lg mt-6">
              Camera will be the highest quality resolutions, excellent view day
              and night and can easily replace older systems utilizing existing
              cabling infrastructure.
              <br />
              CCTV analytics can be a very useful tool and can be programmed to
              monitor activity in its view to provide alert / desired warning if
              required. Analytics such as: Thermal Temperature Screening;
              Intrusion Detection; Line Crossing; ANPR; Many more...
            </p>
          </div>
        }
      />
      <Content
        id={"access-control"}
        heading={"Access Control"}
        headingColor={"text-blue"}
        imageSrc={doorAutomationImage}
        imageAlt={"Door automation system"}
        imagePosition={"right"}
        bgColor={"bg-white"}
        text={
          <div>
            <p className="text-lg">
              Access control supports premise security and restricts access to
              the right personnel only.
              <br />
              SafeTec Fire & Security has the expertise to design and install an
              access control system to suit all customer needs.
              <br />
              Access control systems can range from a single door and Intercom
              solution, through to a multi door, multi permission level system
              with secure hierarchy permissions.
              <br />
              We also supply and install ID Card Printers and Prox Cards.
              Whatever the requirement, we can cater to your needs.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">
              Specialist Lock Fitting
            </h1>
            <p className="text-lg mt-2">
              We have the skilled engineers to install all specialist types of
              locks from Abloy mechanical / motorized to Electromagnetic Locks
              (Maglocks).
            </p>
          </div>
        }
      />
      <Content
        id={"intercoms"}
        heading={"Intercoms"}
        headingColor={"text-white"}
        imageSrc={intercomImage}
        imageAlt={"Intercom system"}
        imagePosition={"left"}
        bgColor={"bg-elm"}
        textColor={"text-white"}
        text={
          <div>
            <p className="text-lg">
              Intercoms are essential for many homeowners and business owners.
              SafeTec F&S can provide a multitude of different systems to enable
              the client to communicate with visitors and release locking
              devices / automatic gates.
            </p>
            <ul className="list-disc list-inside text-lg mt-6">
              <li>Audio/video as standard</li>
              <li>Stylish and contemporary designs</li>
              <li>LCD Handsets</li>
              <li>
                1 to 1 kits or Scalable Multi-Handset – Multi-Door Station
                designs
              </li>
              <li>GSM options for remote locations</li>
            </ul>
          </div>
        }
      />
      <Content
        id={"wireless-networking"}
        heading={"Wireless and Networking"}
        headingColor={"text-blue"}
        imageSrc={networksImage}
        imageAlt={"Network cables"}
        imagePosition={"right"}
        bgColor={"bg-white"}
        text={
          <div>
            <p className="text-lg">
              Networks and WiFi are a critical aspect of any business and need
              to be strong and reliable throughout. We can provide the essential
              equipment to give you structure to empower your business to
              provide the availability for network growth and wireless range to
              cover every area required.
            </p>
          </div>
        }
      />
      <Slideshow images={slideImages} />
    </div>
  );
}

export default Commercial;
