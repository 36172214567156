import React from "react";
import premisesHeader from "../../assets/premises/premises-header.jpg";
import Header from "../general/Header";
import Content from "../general/Content";
import { Helmet } from "react-helmet";
import fireExtinguisher from "../../assets/premises/fire-extinguisher.jpg";
import hearingLoop from "../../assets/premises/hearing-loop.jpg";
import riskAssessment from "../../assets/premises/building-fire.jpg";
import disabledRefuge from "../../assets/premises/disabled-refuge.jpg";
import nurseCall from "../../assets/premises/nurse-call.jpg";
import fireAlarm from "../../assets/premises/fire-alarm.jpg";
import paSystem from "../../assets/premises/pa-system.jpg";
import doorAutomation from "../../assets/premises/door-automation.jpg";
import slide1 from "../../assets/premises/premises-slides/slide1.jpg";
import slide2 from "../../assets/premises/premises-slides/slide2.jpg";
import slide3 from "../../assets/premises/premises-slides/slide3.jpg";
import slide4 from "../../assets/premises/premises-slides/slide4.jpg";
import slide5 from "../../assets/premises/premises-slides/slide5.jpg";
import slide6 from "../../assets/premises/premises-slides/slide6.jpg";
import slide7 from "../../assets/premises/premises-slides/slide7.jpg";
import slide8 from "../../assets/premises/premises-slides/slide8.jpg";
import Slideshow from "../general/Slideshow";

const slideImages = [
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
];

function Premises() {
  return (
    <div>
      <Helmet>
        <title>
          Life and Premises Safety Solutions - SafeTec Fire & Security Ltd.
        </title>
        <meta
          name="description"
          content="Fire alarms, fire extinguishers, fire risk assessments, nursecall systems,
          disabled refuge systems, deaf loop aids, public address systems, and door automation"
        />
        <meta
          name="keywords"
          content="SafeTec Fire & Security, North Down, Northern Ireland, professional, cost-effective, reliable, fire solutions, security solutions, domestic customers, commercial customers, industry-leading, expertise"
        />
      </Helmet>
      <Header
        title="Life & Premises Safety Solutions"
        backgroundImage={premisesHeader}
      />
      <Content
        id={"life-safety"}
        heading={"Fire Alarm Systems"}
        headingColor={"text-blue"}
        imageSrc={fireAlarm}
        imageAlt={"A fire alarm on a wall"}
        text={
          <div>
            <p className="text-lg">
              Fire alarms and life safety solutions are an essential front-line
              technology. They are a legal requirement for protection and damage
              limitation to people and premises.
              <br />
              At SafeTec F&S, we have the accreditation to design, install,
              commission and maintain all type of fire alarms.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">
              Addressable System - Small, Medium, Large Premises
            </h1>
            <p className="text-lg mt-2">
              Addressable systems are the optimum system that can quickly detect
              fire or fault and alert to an exact LCD descripted device. The
              Addressable systems can provide redundancy by Loop configurations
              and isolation devices so that in the event of a cut cable, the
              operability of the system continues without fail.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">
              Conventional System - Small, Medium Premises
            </h1>
            <p className="text-lg mt-2">
              Conventional systems are a more cost effective yet very reliable
              method of protection. When installed correctly, the System will
              monitor the premises via Zoning. Zoning would be radial circuits
              wired through a precise area in a designed route to deploy
              detection and alarm devices where necessary as per Fire Alarm
              Category required.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">
              Wireless Systems
            </h1>
            <p className="text-lg mt-2">
              Wireless systems can be a useful setup to suit the more bespoke or
              restrictive locations. All detection and alarm devices battery
              operated and manufactured to the required standards and
              regulations.
            </p>
            <h1 className="text-xl font-bold text-blue mt-6">
              Aspirating Systems - Small, Medium, Large Premises
            </h1>
            <p className="text-lg mt-2">
              Aspirating Systems are a detection device that samples air
              throughout a premises by creating a vacuum and drawing in air
              through sample points dotted along lengths of pipework. This
              detection device can be installed in many different environments
              such as Server Rooms, Voids, Refrigeration Areas and many more. It
              is designed to be a very early warning device as sensitivity
              values can be optimized to detect and alert very efficiently.
            </p>
            <p className="text-lg mt-6">
              All systems are installed and maintained and comply with all UK
              Fire Regulations and standards{" "}
              <span className="font-bold">BS 5839-1:2017</span>
              <br />
              Remote Monitoring to our UK based ARC can be implemented and Key
              Holder Response facilitated.
            </p>
          </div>
        }
      />
      <Content
        id={"risk-assessments"}
        heading={"Fire Risk Assessments"}
        headingColor={"text-white"}
        bgColor={"bg-elm"}
        imageSrc={riskAssessment}
        imageAlt={"A burning building"}
        imagePosition={"left"}
        textColor={"text-white"}
        text={
          <div>
            <p className="text-lg mt-2">
              Under the{" "}
              <span className="font-bold">
                Regulatory Reform (Fire Safety) Order 2005
              </span>
              , all businesses must comply with fire safety law, carry out fire
              risk assessments and identify general fire.
              <br />
              SafeTec Fire & Security can assist with fire risk assessments and
              design compliant fire category in line with risk assessment and
              insurance requirements. Whatever the premises, we can implement
              the right package to suit your needs and specified category.
            </p>

            <h1 className="text-xl font-bold text-white mt-6">
              Understanding Fire Alarm Categories
            </h1>
            <h1 className="text-lg font-bold text-white mt-6">
              BS5839-1 Classification L1 Explanation
            </h1>
            <p className="text-lg mt-2">
              A BS5839-1 L1 classification includes automatic fire detection in
              all rooms, on all escape routes and in all voids over 800mm in
              height. Sounders positioned throughout the building to achieve a
              minimum of 65dB(A) throughout the building and 75dB(A) at bedhead
              where there is a sleeping risk. In areas of high ambient noise
              sound levels the fire alarm sound levels should be 5dB(A) above
              the normal noise level although not exceeding 120dB(A).
            </p>
            <h1 className="text-lg font-bold text-white mt-6">
              BS5839-1 Classification L2 Explanation
            </h1>
            <p className="text-lg mt-2">
              A BS5839-1 Classification L2 System should include automatic fire
              detection on all escape routes and rooms leading onto escape
              routes. An L2 system can also include additional areas deemed as a
              high risk not included in the escape routes and adjoining rooms,
              such as boiler houses. The sounders in the building should be
              according to the description in the BS5839-1 Category L1
              description as above.
            </p>
            <h1 className="text-lg font-bold text-white mt-6">
              BS5839-1 Classification L3 System Explanation
            </h1>
            <p className="text-lg mt-2">
              A BS5839-1 Classification L3 system is very similar to a category
              L2 system in that automatic fire detection should be positioned on
              escape routes and adjoining rooms, although it does not have to
              include for additional areas deemed to have a high fire risk. The
              sounders in the building should be according to the description in
              the BS5839-1 Category L1 description as above.
            </p>
            <h1 className="text-lg font-bold text-white mt-6">
              BS5839-1 Classification L4 System Explanation
            </h1>
            <p className="text-lg mt-2">
              A BS5839-1 Classification L4 system includes automatic fire
              detection on escape routes only, and not in the adjoining rooms as
              per the L2 and L3 classifications. The sounders in the building
              should be according to the description in the BS5839-1 Category L1
              description as above.
            </p>
            <h1 className="text-lg font-bold text-white mt-6">
              BS5839-1 Classification L5 System Explanation
            </h1>
            <p className="text-lg mt-2">
              A BS5839-1 Classification L5 System is designed for buildings that
              have a particular fire risk identified which warrants some special
              attention. For example if there is an area of high fire risk which
              is considered worthy of having some automatic detection but a
              manual system is also needed, then this will be termed as L5/M.
              The sounders in the building should be according to the
              description in the BS5839-1 Category L1 description as above.
            </p>
            <h1 className="text-lg font-bold text-white mt-6">
              Category M Fire Alarm Systems
            </h1>
            <p className="text-lg mt-2">
              A BS5839-1 Category M Fire Alarm System is a manual operation only
              system which has call points on all exits as well as corridors
              where persons are not expected to walk any more than 45m to
              operate one.
            </p>
          </div>
        }
      />
      <Content
        id={"fire-extinguishers"}
        heading={"Fire Extinguishers"}
        headingColor={"text-blue"}
        imageSrc={fireExtinguisher}
        imageAlt={"A fire extinguisher on a wall"}
        imagePosition={"right"}
        text={
          <div>
            <p className="text-lg">
              Fire extinguishers are an essential part of fire safety in any
              building, but they require regular maintenance to ensure they are
              ready to use in the event of a fire. In the UK, fire extinguisher
              maintenance is governed by the British Standard BS 5306-3:2017.
              This standard outlines the requirements for the commissioning,
              installation, and maintenance of portable fire extinguishers.
              Under this standard, fire extinguishers must be inspected at least
              once a year by a competent person who is certified to carry out
              fire extinguisher maintenance. During the inspection, the
              technician will check that the extinguisher is in good condition,
              the pressure is correct, and the contents have not settled or
              become contaminated.
              <br />
              Additionally, fire extinguishers must be serviced every five years
              to ensure they are fully operational. During a service, the
              technician will empty and refill the extinguisher, check for any
              signs of corrosion or damage, and replace any defective parts.
              <br />
              It's important to note that fire extinguisher maintenance is not
              only a legal requirement but also essential for the safety of the
              building and its occupants. Regular maintenance can help ensure
              that fire extinguishers are functioning properly and can be relied
              upon in the event of a fire.
            </p>
          </div>
        }
      />
      <Content
        id={"hearing-loop"}
        heading={"Hearing Loop Systems"}
        headingColor={"text-white"}
        textColor={"text-white"}
        bgColor={"bg-elm"}
        imageSrc={hearingLoop}
        imageAlt={"A hearing loop system"}
        imagePosition={"left"}
        text={
          <div>
            <p className="text-lg">
              Deaf loop installations, also known as hearing loop systems, are a
              type of assistive technology designed to improve accessibility for
              individuals with hearing loss. These systems work by using a
              magnetic field to transmit sound directly to a hearing aid or
              cochlear implant, providing a clear and amplified signal without
              the need for additional equipment.
              <br />
              Deaf loop installations typically involve the installation of a
              loop of wire around a designated area, such as a meeting room or
              ticket counter, which is then connected to an amplifier or sound
              system. When a person with a compatible hearing device enters the
              loop, they are able to hear the sound being transmitted directly
              to their device, without interference from background noise or
              distance from the sound source.
              <br />
              Deaf loop installations are becoming increasingly common in public
              spaces such as theaters, places of worship, and transportation
              hubs, as they provide a cost-effective and non-intrusive solution
              for improving accessibility for individuals with hearing loss. By
              investing in deaf loop installations, organizations can
              demonstrate their commitment to inclusivity and make their
              services accessible to a wider range of individuals.
            </p>
          </div>
        }
      />
      <Content
        id={"disabled-refuge"}
        heading={"Disabled Refuge Systems"}
        headingColor={"text-blue"}
        imagePosition={"right"}
        imageSrc={disabledRefuge}
        imageAlt={"A disabled refuge system"}
        text={
          <div>
            <p className="text-lg">
              In any non-domestic building with more than one floor, in the
              event of a fire, or other emergency, staff evacuate
              wheelchair-restricted customers who cannot easily use fire escapes
              via lifts & stairs during an emergency to a designated place of
              safety (Refuge Area).
              <br />
              Staff then activate the Refuge Area Remote Unit, which lights a
              Refuge Occupied indicator on the main Control Panel. The Fire
              Officer (or Building Manager) is then able to communicate with the
              occupants of the refuge areas via an effective two-way
              communication system.
              <br />
              The Equality Act and Disability Discrimination Act made it the
              responsibility of all companies, nationwide, to ensure that access
              to buildings and services is available to everyone – there must be
              no discrimination.
              <br />
              As part of “The Disabled Discrimination Act (DDA) 2004″, “The
              Regulatory Reform Order (Fire safety law) otherwise known as the
              RRO & current building regulations mean it is now part of fire
              safety law to provide a safe means of refuge for disabled persons
              in the event of a fire or emergency.
              <br />
              Points are usually situated on a landing or staircase along the
              main escape route.
              <br />
              All our systems are designed, installed & commissioned to{" "}
              <span className="font-bold">BS 5839 Part 8 / 9 and BS 5588.</span>
            </p>
          </div>
        }
      />
      <Content
        id={"nurse-call"}
        heading={"Nurse Call and Warden Call Systems"}
        headingColor={"text-white"}
        bgColor={"bg-elm"}
        textColor={"text-white"}
        imageSrc={nurseCall}
        imageAlt={"A nurse call system"}
        imagePosition={"left"}
        text={
          <div>
            <p className="text-lg">
              Nurse call systems are of vital importance in many regions of
              Nurse to Patient interfacing. Primarily in care homes and
              hospitals but also in many other areas. We can provide a variant
              of different methods to facilitate the service required.
            </p>
            <ul className="list-disc list-inside text-lg mt-6">
              <li>Residential Care Homes</li>
              <li>Nursing Homes</li>
              <li>Doctor Surgeries</li>
              <li>Hospitals</li>
              <li>Dentist Surgeries</li>
              <li>Vulnerable Patients Home Alone</li>
            </ul>
            <h1 className="text-2xl font-bold mt-6">Warden Call Systems</h1>
            <p className="text-lg mt-2">
              A warden call system is a type of emergency communication system
              used in buildings such as hospitals, care homes, and assisted
              living facilities to alert staff in the event of an emergency.
              <br />
              These systems typically consist of a central control unit that
              receives alerts from various points throughout the building, such
              as call points in patient rooms or pull cords in bathrooms. When
              an alert is triggered, staff members are notified through a
              combination of visual and audible signals, allowing them to
              respond quickly and efficiently to the emergency.
              <br />
              Warden call systems may also include features such as two-way
              communication, enabling staff members to communicate directly with
              patients or residents, and integration with other safety systems
              such as fire alarms and security systems.
              <br />
              Overall, warden call systems are an essential component of a
              comprehensive emergency management plan for any facility that
              cares for vulnerable individuals. By providing reliable and
              efficient communication in the event of an emergency, they help
              ensure the safety and wellbeing of both staff and residents.
            </p>
          </div>
        }
      />
      <Content
        id={"door-automation"}
        heading={"Door Automation"}
        headingColor={"text-blue"}
        imageSrc={doorAutomation}
        imageAlt={"A door automation system"}
        imagePosition={"right"}
        text={
          <div>
            <p className="text-lg">
              Door automation installations involve the installation of
              motorized devices that allow doors to open and close
              automatically, without the need for manual operation. These
              systems can be used in a wide variety of settings, from commercial
              buildings to residential homes, and offer a range of benefits such
              as increased accessibility, improved security, and enhanced
              convenience.
              <br />
              Door automation systems typically consist of a motorized mechanism
              that is installed in the door frame, along with sensors and
              control units that allow the system to detect when someone is
              approaching the door and to open and close the door accordingly.
              These systems can be configured in a variety of ways to suit
              different needs, from simple push-button systems to more complex
              systems that can be controlled remotely or integrated with other
              security systems.
              <br />
              Overall, door automation installations are a great way to improve
              the functionality and security of your building, while also
              enhancing the user experience for residents or visitors. Whether
              you're looking to improve accessibility for people with
              disabilities or simply want to make your building more efficient
              and convenient, a door automation system can be a valuable
              investment.
            </p>
            <h1 className="text-lg font-bold text-blue mt-6">
              Electronic Door Holders
            </h1>
            <p className="text-lg mt-2">
              An electronic door holder is a device that can be installed on a
              door to hold it open electronically. This is useful in situations
              where a door needs to be held open for extended periods of time,
              such as in busy commercial or healthcare settings.
              <br />
              Electronic door holders typically consist of a magnet or
              electromagnet that is mounted on the wall or floor and a metal
              plate that is installed on the door. When the device is activated,
              the magnet holds the metal plate in place, keeping the door open.
              <br />
              These devices can be activated manually using a switch or
              automatically using sensors that detect movement or the presence
              of people. They may also be integrated with other building
              systems, such as fire alarms, to automatically release the door
              and allow it to close in the event of an emergency.
              <br />
              Overall, electronic door holders are a convenient and reliable way
              to hold doors open while maintaining safety and security in
              commercial and healthcare settings.
            </p>
          </div>
        }
      />
      <Content
        id={"public-address"}
        heading={"Public Address Systems"}
        headingColor={"text-white"}
        textColor={"text-white"}
        bgColor={"bg-elm"}
        imageAlt={"A busy public area"}
        imageSrc={paSystem}
        imagePosition={"left"}
        text={
          <div>
            <p className="text-lg">
              Public address installations are sound systems designed for
              broadcasting audio messages or music to a large audience in public
              spaces such as stadiums, convention centers, airports, and train
              stations. They consist of a combination of speakers, amplifiers,
              and microphones that work together to deliver clear and
              intelligible sound over a wide area.
              <br />
              These systems can be used for a variety of purposes, including
              making announcements, providing instructions, playing background
              music, and delivering emergency alerts. PA systems are also
              commonly used in commercial settings, such as retail stores and
              restaurants, to provide background music and create a pleasant
              atmosphere.
              <br />
              When designing a PA installation, it's important to consider
              factors such as the size and layout of the space, the intended use
              of the system, and the acoustic properties of the environment.
              Professional installation and ongoing maintenance are also
              critical to ensure that the system remains reliable and effective
              over time.
              <br />
              Overall, public address installations are a powerful tool for
              communicating with large audiences in public spaces, and can
              enhance the safety, comfort, and overall experience of those in
              the vicinity.
            </p>
          </div>
        }
      />
      <Slideshow images={slideImages} />
    </div>
  );
}

export default Premises;
