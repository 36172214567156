import React from "react";

function Content({
  id,
  heading,
  text,
  bgColor,
  headingColor,
  textColor,
  imageSrc,
  imageAlt,
  imagePosition,
}) {
  return (
    <section id={id} className={`${bgColor} text-left`}>
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 sm:px-6 lg:px-8">
        <h1 className={`my-8 text-3xl font-bold sm:text-4xl ${headingColor}`}>
          {heading}
        </h1>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 items-center">
          <div
            className={`relative h-auto overflow-hidden sm:h-80 lg:h-full flex justify-center ${
              imagePosition === "left" ? "lg:justify-start" : "lg:justify-end"
            }`}
          >
            <div className="text-left md:py-6">
              <div className={`text-lg leading-relaxed ${textColor}`}>{text}</div>
            </div>
          </div>
          {imageSrc && (
            <div
              className={`relative h-64 overflow-hidden sm:h-80 lg:h-full ${
                imagePosition === "left"
                  ? "order-last lg:order-first"
                  : "order-first lg:order-last"
              }`}
            >
              <img
                src={imageSrc}
                className="absolute inset-0 w-full h-[80%] object-cover object-center"
                alt={imageAlt}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Content;
