import React from "react";

const ReviewItem = ({ quote, name, reviewLink }) => {
  return (
    <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
      <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
        <div className="p-6">
          <blockquote>
            <p className="text-lg font-medium text-gray-900">"{quote}"</p>
          </blockquote>
          <div className="mt-6">
            <p className="text-base font-medium text-gray-900">{name}</p>
            <a
              href={reviewLink}
              className="font-bold hover:text-elm duration-300"
              target="_blank"
              rel="noreferrer"
            >
              Read the full review
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
