import React from "react";
import Header from "../general/Header";
import Content from "../general/Content";
import projectsHeader from "../../assets/projects/projects-header.jpg";
import annSlide1 from "../../assets/projects/ann-summers-slides/slide1.jpg";
import annSlide2 from "../../assets/projects/ann-summers-slides/slide2.jpg";
import annSlide3 from "../../assets/projects/ann-summers-slides/slide3.jpg";
import canvasSlide1 from "../../assets/projects/canvas-slides/slide1.jpg";
import canvasSlide2 from "../../assets/projects/canvas-slides/slide2.jpg";
import canvasSlide3 from "../../assets/projects/canvas-slides/slide3.jpg";
import portSlide1 from "../../assets/projects/portaferry-slides/slide1.jpg";
import portSlide2 from "../../assets/projects/portaferry-slides/slide2.jpg";
import portSlide3 from "../../assets/projects/portaferry-slides/slide3.jpg";
import portSlide4 from "../../assets/projects/portaferry-slides/slide4.jpg";
import portSlide5 from "../../assets/projects/portaferry-slides/slide5.jpg";
import Slideshow from "../general/Slideshow";

const annImages = [annSlide1, annSlide2, annSlide3];
const canvasImages = [canvasSlide1, canvasSlide2, canvasSlide3];
const portImages = [portSlide1, portSlide2, portSlide3, portSlide4, portSlide5];

function Projects() {
  return (
    <div>
      <Header title="Projects" backgroundImage={projectsHeader} />
      <Content
        id={"portaferry-hotel"}
        heading={"Portaferry Hotel"}
        headingColor={"text-blue"}
        text={
          <div>
            <p className="text-lg">
              Our client at the stunning Portaferry Hotel allowed us the
              pleasure to upgrade their 8 Zone Conventional Fire Alarm to a
              modern Addressable 2 Loop System. The modernised Addressable
              system shows precise pin pointing of activated alarms and allows
              redundancy operation to remain fully operational if faults occur.
              This project was a pleasure to be involved in.
            </p>
          </div>
        }
      />
      <Slideshow images={portImages} />
      <Content
        id={"canvas-art-gallery"}
        heading={"Canvas Art Gallery"}
        headingColor={"text-white"}
        textColor={"text-white"}
        bgColor={"bg-elm"}
        imagePosition={"left"}
        text={
          <div>
            <p className="text-lg">
              Safetec was asked to install a new fire alarm from start to finish
              at the beautiful Canvas Art Gallery on the Lisburn Road. We
              installed a 4 Zone Conventional Fire Alarm System and commissioned
              to our high standards.
            </p>
          </div>
        }
      />
      <Slideshow images={canvasImages} />
      <Content
        id={"ann-summers"}
        heading={"Ann Summers"}
        headingColor={"text-blue"}
        text={
          <div>
            <p className="text-lg">
              Safetec Fire & Security was asked to Commission the new
              Addressable Fire Alarm in the brand new Ann Summers store in
              Belfast. System was commissioned to L1 Category and we were
              delighted with the finished result.
            </p>
          </div>
        }
      />
      <Slideshow images={annImages} />
    </div>
  );
}

export default Projects;
