import React from "react";
import { NavLink } from "react-router-dom";

const Hero = () => {
  return (
    <section className="hero-bg md:mt-[200px] relative">
      <div className="absolute inset-0 sm:bg-transparent bg-black opacity-40 md:opacity-100 md:bg-gradient-to-r md:from-black/75 md:to-white/25"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
        <div className="max-w-xl text-left">
          <h1 className="text-white font-extrabold text-4xl md:text-5xl">
            Cost Effective and Professional,
            <strong className="block font-extrabold text-blue">
              Safe Keeping Technology
            </strong>
          </h1>

          <p className="text-white font-bold mt-4 max-w-lg text-lg sm:leading-relaxed">
            SafeTec Fire & Security is based in North Down and serves all of
            Northern Ireland. We provide professional, cost effective and
            reliable fire and security solutions to both domestic and commercial
            customers. We have the experience and expertise to provide you with
            industry leading solutions.
          </p>

          <div className="mt-8 flex flex-wrap gap-4 text-center">
            <span
              className="block rounded bg-blue px-12 py-3 text-sm font-medium text-white shadow hover:bg-white hover:text-blue duration-300 focus:outline-none focus:ring active:bg-elm"
            >
              <NavLink to="/About">Learn More</NavLink>
            </span>

            <span
              className="block rounded bg-white px-12 py-3 text-sm font-medium text-blue shadow hover:bg-blue hover:text-white duration-300 focus:outline-none focus:ring active:text-elm"
            >
              <NavLink to="/Contact">Contact Us</NavLink>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
